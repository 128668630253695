<template>
  <div class="lesson-comment">
    <h6 class="mb-1">
      {{ comment.user.name }}
      <small class="fw-normal text-secondary">{{ time(comment) }}</small>

      <el-dropdown
        v-if="user.id == comment.user.id"
        trigger="click"
        class="el-dropdown"
      >
        <span class="el-dropdown-link">
          <i class="fa-regular fa-ellipsis-vertical lead"></i>
        </span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item @click="$emit('edit', comment)">
              Editar
            </el-dropdown-item>
            <el-dropdown-item @click="$emit('remove', comment)">
              Remover
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </h6>
    <div class="mb-2">{{ comment.text }}</div>
    <button
      class="btn btn-sm p-0 text-secondary"
      @click="$emit('reply', comment)"
    >
      <i class="fas fa-reply"></i> Responder
    </button>

    <comment
      v-for="child in comment.childers"
      :key="child.id"
      :comment="child"
      @edit="$emit('edit', child)"
      @remove="$emit('remove', child)"
      @reply="$emit('reply', child)"
    />
  </div>
</template>

<script>
import dayjs from "dayjs";
import "dayjs/locale/pt";
import relativeTime from "dayjs/plugin/relativeTime";
import { mapState } from "vuex";

dayjs.extend(relativeTime);
dayjs.locale("pt");

export default {
  name: "comment",
  props: {
    comment: Object,
  },
  computed: {
    ...mapState("accounts", ["user"]),
  },
  methods: {
    time(comment) {
      return dayjs().to(dayjs(comment.created_at).add(3, "hour"));
    },
  },
};
</script>

<style lang="scss" scoped>
.lesson-comment {
  margin: 16px 0px;
  border-radius: 8px;
  padding: 16px 16px 10px;
  background: rgba(255, 255, 255, 0.03);
  font-size: 14px;

  h6 {
    position: relative;

    small {
      margin-left: 4px;
    }
  }

  .btn-sm {
    font-size: 12px;
  }

  .el-dropdown {
    position: absolute;
    top: 0;
    right: 0;
  }
}
</style>
