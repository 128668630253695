<template>
  <action-dispatcher
    action="courses/getLesson"
    class="view-lesson container py-5"
    :parameters="$route.params.id"
    @success="onSuccess"
  >
    <div
      class="pb-4 text-secondary mobile-small d-flex d-lg-block align-items-center"
    >
      <el-dropdown
        trigger="click"
        class="el-dropdown d-lg-none"
        placement="bottom-start"
      >
        <span class="el-dropdown-link">
          <span class="text-light">...</span>
          <i class="fa-regular fa-chevron-right mx-2"></i>
        </span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item
              v-for="route in breadcrumb"
              :key="route.name"
              @click="$router.push(route.route)"
            >
              {{ route.name }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>

      <span
        v-for="route in breadcrumb"
        :key="route.name"
        class="d-none d-lg-inline"
      >
        <router-link class="text-light text-decoration-none" :to="route.route">
          {{ route.name }}
        </router-link>
        <i class="fa-regular fa-chevron-right mx-2"></i>
      </span>

      {{ lesson.name }}
    </div>

    <live v-if="showLive" :lesson="lesson" />

    <div v-else>
      <video-player
        v-if="video"
        :key="video.id"
        :resource="video"
        :course="lesson.course"
        :finished="finished"
        :poster="lesson.cover"
        @finish="finished = true"
        class="mb-4"
      />

      <div class="d-flex align-items-center justify-content-between mb-4">
        <button @click="finish" class="btn btn-outline-primary">
          <template v-if="finished">
            Assistida <i class="fa-regular fa-check ms-1"></i>
          </template>
          <template v-else>
            Marcar como assistida <i class="fa-regular fa-eye ms-1"></i>
          </template>
        </button>

        <next-lesson v-if="lesson.module" />
      </div>

      <h2 class="font-serif mb-5">{{ lesson.name }}</h2>
      <div v-html="lesson.description"></div>

      <template v-if="pdfs.length">
        <h2 class="title font-serif mt-5">Material de apoio</h2>
        <div class="mx-n2">
          <a
            class="btn btn-material btn-outline-light d-inline-flex align-items-center m-2"
            :href="material.archive"
            target="_blank"
            v-for="material in pdfs"
            :key="material.id"
          >
            {{ material.name }}
            <i class="fa-regular fa-download fa-lg ms-3"></i>
          </a>
        </div>
      </template>

      <comments class="mt-5" :lesson="$route.params.id" />
    </div>
  </action-dispatcher>
</template>

<script>
import { mapState } from "vuex";
import { get } from "lodash";
import VideoPlayer from "@/components/lesson/VideoPlayer";
import Live from "@/components/lesson/Live";
import Comments from "@/components/lesson/Comments";
import NextLesson from "@/components/lesson/NextLesson";

export default {
  components: { VideoPlayer, Live, Comments, NextLesson },
  data() {
    return {
      finished: false,
      savingProgress: false,
    };
  },
  computed: {
    ...mapState("courses", ["lesson"]),
    breadcrumb() {
      return [
        {
          name: "Início",
          route: { name: "index" },
        },
        {
          name: this.lesson?.course.name,
          route: {
            name: "course",
            params: {
              id: this.lesson?.course.id,
              slug: this.lesson?.course.slug,
            },
          },
        },
        {
          name: this.lesson?.module.name,
          route: {
            name: "course",
            params: {
              id: this.lesson?.course.id,
              slug: this.lesson?.course.slug,
            },
            query: { module: this.lesson?.module.id },
          },
        },
      ];
    },
    resourses() {
      return get(this.lesson, "resourses") || [];
    },
    videos() {
      return this.resourses.filter((r) => r.type === "videos");
    },
    video() {
      return get(this.videos, "0");
    },
    pdfs() {
      return this.resourses.filter((r) => r.type === "pdf");
    },
    showLive() {
      return (
        this.lesson.is_live || (this.videos.length === 0 && this.lesson.url)
      );
    },
  },
  methods: {
    onSuccess() {
      this.finished = !!get(this.video, "progress.finished_at");
    },
    finish() {
      if (this.savingProgress) return;
      this.savingProgress = true;
      this.finished = !this.finished;
      this.$store
        .dispatch("progress/save", {
          course_id: this.lesson.course.id,
          resource_id: this.video.id,
          finished: this.finished,
        })
        .catch(() => (this.finished = !this.finished))
        .finally(() => (this.savingProgress = false));
    },
  },
};
</script>
