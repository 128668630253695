<template>
  <div class="lesson-live">
    <div class="row g-4">
      <div class="col-lg-8">
        <div id="ytVideo" class="ratio ratio-16x9">
          <iframe
            :src="`https://www.youtube.com/embed/${youtubeId}?autoplay=1&rel=0&controls=0`"
            allowfullscreen
            allow="autoplay"
          ></iframe>
        </div>

        <div class="d-flex align-items-center justify-content-start pt-4">
          <span class="lesson-number me-3 bg-dark">
            <img src="@/assets/images/live.svg" /> Ao vivo
          </span>
          <div>
            <h3 class="fw-normal mb-0">{{ lesson.name }}</h3>
          </div>
        </div>

        <div v-html="lesson.description" class="mt-4"></div>
      </div>

      <div class="col-lg-4">
        <div class="ratio" style="--bs-aspect-ratio: 116%">
          <iframe :src="chatUrl" allowfullscreen></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get } from "lodash";
import { youtubeParser } from "@/functions";
import Plyr from "plyr";
import isMobile from "ismobilejs";

export default {
  props: {
    lesson: Object,
  },
  computed: {
    youtubeId() {
      return youtubeParser(get(this.lesson, "url"));
    },
    chatUrl() {
      return `https://www.youtube.com/live_chat?v=${this.youtubeId}&embed_domain=${document.location.hostname}`;
    },
  },
  methods: {
    initPlyr() {
      if (isMobile(window.navigator).any) {
        return;
      }

      setTimeout(() => {
        new Plyr(document.getElementById("ytVideo"), {
          autoplay: true,
          controls: [
            "play-large",
            "play",
            "mute",
            "volume",
            "settings",
            "fullscreen",
          ],
          ratio: "16:9",
          i18n: {
            speed: "Velocidade",
            quality: "Qualidade",
            qualityLabel: {
              0: "Auto",
            },
          },
        });
      }, 1);
    },
  },
};
</script>

<style lang="scss">
.lesson-live {
  @media screen and (max-width: 991px) {
    flex-direction: column-reverse;
  }

  @media (max-width: 768px) {
    small {
      font-size: 12px !important;
    }
  }

  .btn {
    line-height: 34px;
  }

  button {
    i {
      font-size: 22px;
    }
  }

  .lesson-number {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #ffffff;
    padding: 10px 24px;
    white-space: nowrap;
  }
}
</style>
