<template>
  <action-dispatcher
    action="courses/getLessons"
    :parameters="lesson.module.id"
    class="lesson-next-lesson"
  >
    <router-link
      v-if="nextLesson"
      class="btn text-primary pe-0"
      :to="{
        name: 'lesson',
        params: { id: nextLesson.id, slug: nextLesson.slug },
      }"
    >
      Próxima <span class="d-none d-md-inline">aula</span>
      <i class="fa-regular fa-chevron-right ms-2"></i>
    </router-link>

    <action-dispatcher
      v-else-if="lesson.course"
      action="courses/get"
      :parameters="lesson.course.id"
    >
      <router-link
        v-if="nextModule"
        class="btn text-primary pe-0"
        :to="{
          name: 'course',
          params: { id: lesson.course.id, slug: lesson.course.slug },
          query: { module: nextModule.id },
        }"
      >
        Próximo <span class="d-none d-md-inline">módulo</span>
        <i class="fa-regular fa-chevron-right ms-2"></i>
      </router-link>
    </action-dispatcher>
  </action-dispatcher>
</template>

<script>
import { mapState } from "vuex";
import { sortBy, get } from "lodash";

export default {
  props: {
    module: Object,
  },
  computed: {
    ...mapState("courses", ["lesson", "lessons", "course"]),
    nextLesson() {
      for (let i = 0; i < this.lessons.length; i++) {
        if (this.lessons[i].id === this.lesson.id) {
          return get(this.lessons, i + 1);
        }
      }
      return null;
    },
    sortedModules() {
      return sortBy(this.course.modules, ["sort"]);
    },
    nextModule() {
      for (let i = 0; i < this.sortedModules.length; i++) {
        if (this.sortedModules[i].id === this.lesson.module.id) {
          return get(this.sortedModules, i + 1);
        }
      }
      return null;
    },
  },
};
</script>
